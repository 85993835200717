import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import StorageHelper from '../storage.helper';
import { TokenDto } from '../../shared/models/Token/TokenDto';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  const token = StorageHelper.getToken();
  if (token) {
    const userToken = JSON.parse(token) as TokenDto;
    const isLoggedIn = !!token;
    const isApiUrl = req.url.startsWith(environment.server);
    if (isLoggedIn && isApiUrl) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${userToken.access}`
        }
      });
    }
  }
  return next(req);
};
