import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, of, throwError } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { Urls } from "../../constants/Urls";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(res => {
      if([401].includes(res.status)) {
        this.authService.logout();
        this.toastrService.error(res.error.detail, res.statusText);
        this.router.navigate([`${Urls.APP}/${Urls.HOME}`]);
      } else if (res.status === 404) {
        if (req.url.indexOf('player-game') >= 0 || req.url.indexOf('game-completed') >= 0) {
          this.toastrService.error('Invalid game ID.');
          this.router.navigate([`${Urls.APP}/${Urls.GAME_HISTORY}`]);
        }
      } else if (res.status === 403) {
        this.toastrService.error(res.error);
      } else if (res.status === 0) {
        this.toastrService.error(res.message);
      }
      else {
        const errors = res.error.error;
        Object.keys(errors).forEach(key => {
          const errorMessage = errors[key];
          if (Array.isArray(errorMessage)) {
            if (errorMessage.length) {
              if (errorMessage.length > 1) {
                let listItems: string[] = [];
                errorMessage.forEach(message => {
                  listItems.push(`<li>${message}</li>`)
                });
                this.toastrService.error(`<ul>${listItems.join('')}</ul>`, `INVALID ${key.toUpperCase()}!`, {
                  enableHtml: true
                });
              } else {
                this.toastrService.error(errorMessage[0], `INVALID ${key.toUpperCase()}!`);
              }
            }
          } else {
            this.toastrService.error(errorMessage);
          }
        })
      }

      const error = res.error.message || res.statusText;
      return throwError(error);
    }))
  }
}

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const toastrService = inject(ToastrService)
  const router = inject(Router);

  return next(req).pipe(catchError(res => {
    if([401].includes(res.status)) {
      authService.logout();
      toastrService.error(res.error.detail, res.statusText);
      router.navigate([`${Urls.APP}/${Urls.HOME}`]);
    } else if (res.status === 404) {
      if (req.url.indexOf('player-game') >= 0 || req.url.indexOf('game-completed') >= 0) {
        toastrService.error('Invalid game ID.');
        router.navigate([`${Urls.APP}/${Urls.GAME_HISTORY}`]);
      }
    } else if (res.status === 403) {
      toastrService.error(res.error);
    } else if (res.status === 0) {
      toastrService.error(res.message);
    }
    else {
      const errors = res.error.error;
      Object.keys(errors).forEach(key => {
        const errorMessage = errors[key];
        if (Array.isArray(errorMessage)) {
          if (errorMessage.length) {
            if (errorMessage.length > 1) {
              let listItems: string[] = [];
              errorMessage.forEach(message => {
                listItems.push(`<li>${message}</li>`)
              });
              toastrService.error(`<ul>${listItems.join('')}</ul>`, `INVALID ${key.toUpperCase()}!`, {
                enableHtml: true
              });
            } else {
              toastrService.error(errorMessage[0], `INVALID ${key.toUpperCase()}!`);
            }
          }
        } else {
          toastrService.error(errorMessage);
        }
      })
    }

    const error = res.error.message || res.statusText;
    return throwError(error);
  }))
};
