<footer class="footer mt-auto py-1 footer-container">
  <div class="container">
    <div class="d-flex h-5 justify-content-center align-items-center">
      <div class="d-flex justify-content-center px-2 h-75 align-items-center divider">
        <span style="color: var(--text-color-primary)">© MPL 2023.</span>
      </div>
      <div class="d-flex justify-content-center px-2 h-75 align-items-center">
        <span style="color: var(--text-color-primary)">Sponsored by Opengrowth</span>
      </div>
    </div>  
  </div>
</footer>