<div class="mt-3 online-players-container">
  @if (onlinePlayers.length) {
    @for (onlinePlayer of onlinePlayers; track trackOnlineStatus) {
      <div class="player-container ps-2 d-flex justify-content-between align-items-center mt-2">
        <div class="w-50 d-flex align-items-center">
          <div class="me-1" [ngClass]="onlinePlayer.onlineStatus === 'online' || onlinePlayer.onlineStatus === 'in_game' ? 'online': 'offline'"></div>
          <p class="m-0 content-text">{{onlinePlayer.user.fullName}}</p>

          @if (onlinePlayer.user.badge) {
            <div class="badge-container">
              <img class="user-badge" [src]="onlinePlayer.user.badge.reward.badge" loading="lazy" [ngbTooltip]="onlinePlayer.user.badge.reward.name" >
            </div>
          }
        </div>

        <div class="d-flex w-25">
          <span class="material-symbols-outlined content-text" style="font-size: 25px;">
            military_tech 
          </span>
          <span class="content-text">{{onlinePlayer.elo}} elo</span>
        </div>

        <div class="d-flex w-25 justify-content-end">
          @switch (onlinePlayer.onlineStatus) {
            @case (onlineStatuses.ONLINE) {
              <app-primary-button label="Invite" (onClick)="invite(onlinePlayer)"></app-primary-button>
            }
            @case (onlineStatuses.OFFLINE) {
              <span class="last-online-dt pe-2">{{onlinePlayer.lastOnlineAt | formatDate}}</span>
            }
            @case (onlineStatuses.IN_GAME) {
              <span class="last-online-dt pe-2">In a match</span>
            }
          }
        </div>
      </div>
    }
  } @else {
    <div class="container text-center">
      <span class="material-symbols-outlined" style="font-size: 55px;color: var(--text-color-primary);">notifications_paused</span>
      <p class="m-0" style="color: var(--text-color-primary);">No online players</p>
    </div>
  }
</div>
