import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Urls } from "../constants/Urls";
import { AuthService } from "../services/auth.service";
import { SignalsService } from "../services/signals.service";
import { SidenavigationTypes } from "../constants/SidenavigationTypes";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private signalService: SignalsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authService.tokenvalue;
    const noAllowedToAuthenticatedUsersUrl = !!['register', 'login'].find((url) => state.url.indexOf(url) >= 0);

    if (state.url.indexOf(Urls.PLAYGROUND) >= 0) {
      this.signalService.sideContentData.set({ type: SidenavigationTypes.DEFAULT });
    }

    if (token) {
      if (noAllowedToAuthenticatedUsersUrl) {
        this.router.navigate(['/']);
        return false;
      }

      return true;
    }

    this.router.navigate(['/']);
    return false;
  }

}

@Injectable({providedIn: 'root'})
export class PreventLoggedInAccess implements CanActivate {
  constructor(private authService: AuthService, private router: Router,) {}

  canActivate() {

    if (!this.authService.tokenvalue) {
      return true;
    }

    this.router.navigate([`/${Urls.APP}/${Urls.GAME_HISTORY}`]);
    return false;
  }
}
