<div class="position-sticky h-100 pt-3">
  @if (sideNavType === sideNavTypes.DEFAULT) {
    <div class="h-75">
      <div class="mt-2">
          <div class="row">
            <div class="col-sm-12 col-md-6 mt-1">
              <app-primary-button label="New game" (onClick)="newGame()"></app-primary-button>
            </div>

            <div class="col-sm-12 col-md-6 mt-1">
              <app-primary-button label="Practice mode" (onClick)="practiceMode()"></app-primary-button>
            </div>
          </div>

          <div class="mt-5 d-none d-sm-none d-md-block">
            <h4 class="title">Players</h4>
            <app-online-players (invitePlayer)="invitePlayer($event)"></app-online-players>
          </div>
      </div>
    </div>
  } @else {
    <app-score-board [game]="sideNavdata.data"></app-score-board>
  }

  @if (userReferralLink) {
    <div class="d-flex flex-column justify-content-end mb-1" style="bottom: 0; position:absolute; width: 100%;">
      <h5 class="title">Invite your friends!</h5>
      <div class="referral-link-container">
        <div class="link m-1" [ngbTooltip]="userReferralLink">Hover over me to see the invite link!</div>
        <div class="copy-control d-flex justify-content-end">
          @if (copying) {
            <span class="link-copied">Link copied</span>
          } @else {
            <span class="material-symbols-outlined copy" ngbTooltip="Copy invite link" (cdkCopyToClipboardCopied)="clipboardCopied()" [cdkCopyToClipboard]="userReferralLink">content_copy</span>
          }
        </div>
      </div>
    </div>
  }
</div>
