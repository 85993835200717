import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, interval, mergeMap, takeUntil } from 'rxjs';
import { Notifications } from '../../models/notifications/Notifications';
import { MatMenuModule } from '@angular/material/menu';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { CommonModule } from '@angular/common';
import { ProblemService } from '../../../services/problem.service';
import { NotificationsStatus } from '../../../constants/NotificationsStatus';
import { Router } from '@angular/router';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../services/notification.service';
import { NotificationsType } from '../../../constants/NotificationTypes';
import { PaginatedResponse } from '../../models/GenericObject';
import { NotificationsDto } from '../../models/notifications/NotificationsDto';
import { GameWsService } from '../../../services/web-sockets/game-ws.service';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [MatMenuModule, PrimaryButtonComponent, CommonModule, FormatDatePipe ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnDestroy, OnInit {
  destroy$ = new Subject<boolean>();
  notificationSubscription: Subscription;
  notifications: Notifications[] = [];
  unreadNotificationsCount: number = 0;

  constructor(
    private problemsService: ProblemService,
    private router: Router,
    private notificationService: NotificationService,
    private gameWebsocket: GameWsService
  ) {
    interval(environment.gameInviteNotificationInterval).pipe(
      takeUntil(this.destroy$),
      mergeMap(() => this.notificationService.getNotifications(NotificationsType.HEADS_UP))
    ).subscribe({
      next: (notificationsResponse: PaginatedResponse<NotificationsDto>) => {
        this.notifications = notificationsResponse.results.map(r =>  new Notifications(r));
        this.unreadNotificationsCount = this.notifications.filter(n => n.status === NotificationsStatus.SENT).length;
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.gameWebsocket.gameInvitesNotifications.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (response: NotificationsDto[]) => {
        const gameInvitesNotifications = response.map(d => new Notifications(d));

        for (let index = 0; index < gameInvitesNotifications.length; index++) {
          const gameNotification = gameInvitesNotifications[index];
          if (gameNotification.notificationType !== NotificationsType.HEADS_UP)  {
            this.notifications.unshift(gameNotification);
          }
        }
        this.notifications = this.notifications.filter(
          (notification, index) => this.notifications.findIndex((n) => notification.id === n.id) === index);
        this.unreadNotificationsCount = this.notifications.filter(n => n.status === NotificationsStatus.SENT).length;
        }
    });
  }

  acceptRejectGameinvite(notification: Notifications, accepted: boolean): void {
    this.problemsService.acceptRejectGameInvite(notification.text.gameId, accepted).subscribe(() => {
      const selectedNotification = this.notifications.find(n => n.id === notification.id);
      if (selectedNotification) {
        selectedNotification.status = NotificationsStatus.READ;
        this.unreadNotificationsCount -= 1;
      }

      if (accepted) {
        this.router.navigate([notification.playGroundRoute.url], { queryParams: notification.playGroundRoute.queryParams });
      }
    });
  }
}
