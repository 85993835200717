import { NotificationsType } from "../../../constants/NotificationTypes";
import { NotificationsStatus } from "../../../constants/NotificationsStatus";
import { Urls } from "../../../constants/Urls";
import { Base } from "../Base";
import { NavigationData } from "../NavigationData";
import { NotificationsDto, NotificationsTextDto } from "./NotificationsDto";


export class Notifications extends Base {
  
  status: NotificationsStatus;
  notificationType: NotificationsType;
  text: NotificationsText;
  deliveredAt: number;
  readAt: number;
  userId: number;
  playGroundRoute: NavigationData;

  constructor(data: NotificationsDto) {
    super(data);

    this.status = data.status;
    this.notificationType = data.notification_type;
    this.text = new NotificationsText(data.text);
    this.deliveredAt = data.delivered_at;
    this.readAt = data.read_at;
    this.userId = data.user;
    this.playGroundRoute = new NavigationData(`/${Urls.APP}/${Urls.PLAYGROUND}`, { gameId: data.text.game_id });
  }
}

export class NotificationsText {
  gameId: number;
  invitedBy: string;
  notificationType: NotificationsType;
  playerId: number;
  userName: string;

  constructor(data: NotificationsTextDto) {
    this.gameId = data.game_id;
    this.invitedBy = data.invited_by;
    this.notificationType = data.notification_type;
    this.playerId = data.player_id;
    this.userName = data.username;
  }
}
