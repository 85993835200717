<header class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow header-bg" style="z-index: 1;">
  @if (authenticated$ | async) {
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" [routerLink]="gameHistoryRoute">
      <img class="logo" [src]="imageAssets.MPL_LOGO" loading="lazy">
      <span>Math Premier League</span>
    </a>

    <ng-container *ngTemplateOutlet="menuItems"></ng-container>

    <div class="navbar-nav d-flex flex-row">
      <div>
        <app-theming-options></app-theming-options>
      </div>
      <div>
        <app-notifications></app-notifications>
      </div>
      <div class="pe-3">
        <app-profile-menu></app-profile-menu>
      </div>
    </div>
  } @else {
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" [routerLink]="homeRoute">
      <img class="logo" [src]="imageAssets.MPL_LOGO" loading="lazy">
      <span>Math Premier League</span>
    </a>

    <ng-container *ngTemplateOutlet="menuItems"></ng-container>

    <div class="navbar-nav d-flex flex-row">
      <div class="nav-item text-nowrap">
        <a class="nav-link px-3" [routerLink]="loginRoute">Login</a>
      </div>
      <div class="nav-item text-nowrap">
        <a class="nav-link px-3" [routerLink]="registerRoute">Register</a>
      </div>
    </div>
    
  }

  <ng-template #menuItems>
    <div class="menu-items">
      <div class="d-flex align-items-center">
        <div class="discord-container d-flex justify-content-center align-items-center me-2">
          <a class="link-label" href="https://discord.com/channels/1172588992135446609/1174611991478927420" target="_blank"><fa-icon [icon]="faDiscord"></fa-icon></a>
        </div>
        <div class="discord-container d-flex justify-content-center align-items-center">
          <a class="link-label" href="https://intgovforum.org/en/content/dynamic-coalition-on-gaming-for-purpose-dc-g4p" target="_blank"><fa-icon [icon]="faGlobe"></fa-icon></a>
        </div>
      </div>
    </div>
  </ng-template>
  
</header>
