import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { NotificationsType } from '../constants/NotificationTypes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly notificationsUrl: string = '/v1/notifications';
  
  constructor(private apiService: ApiService) { }

  getNotifications(notification_type?: NotificationsType): Observable<any> {
    if (notification_type) {
      return this.apiService.get(`${this.notificationsUrl}/?notification_type=${notification_type}`);
    } else {
      return this.apiService.get(`${this.notificationsUrl}/`);
    }
    
  }
}
