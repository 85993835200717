<app-header></app-header>
<div class="container" [class.row]="authenticated$ | async">
  <nav id="sidebarMenu" class="col-md-4 col-lg-3 d-md-block sidebar collapse side-navigation-container" *ngIf="authenticated$ | async">
    <app-side-content></app-side-content>
  </nav>
  <div class="main-body ms-sm-auto"  [ngClass]="(authenticated$ | async) ? 'col-md-8 col-lg-9 pt-3' : 'col-md-12 col-lg-12'">
    <router-outlet />
  </div>
</div>
<app-footer></app-footer>
