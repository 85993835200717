import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SideContentComponent } from './shared/components/side-content/side-content.component';
import { SignalsService } from './services/signals.service';
import { SidenavigationTypes } from './constants/SidenavigationTypes';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, 
    HeaderComponent, 
    CommonModule, 
    FooterComponent,
    SideContentComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Math Premier League';
  authenticated$: Observable<boolean>

  constructor(private authService: AuthService, private signalService: SignalsService) {
    this.authenticated$ = this.authService.authenticated$;
  }
}
