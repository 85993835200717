import { AfterViewInit, Component, OnDestroy, OnInit, effect } from '@angular/core';
import { SidenavigationTypes } from '../../../constants/SidenavigationTypes';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { OnlinePlayersComponent } from '../online-players/online-players.component';
import { Player } from '../../models/player/Player';
import { ScoreBoardComponent } from '../score-board/score-board.component';
import { SideNavigation } from '../../models/SideNavigation';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PlaygroundService } from '../../../services/playground.service';
import { Router } from '@angular/router';
import { Urls } from '../../../constants/Urls';
import { SignalsService } from '../../../services/signals.service';
import { EMPTY, Subject, mergeMap, takeUntil } from 'rxjs';
import { AccountsService } from '../../../services/accounts.service';
import { AuthService } from '../../../services/auth.service';
import { GameWsService } from '../../../services/web-sockets/game-ws.service';

@Component({
  selector: 'app-side-content',
  standalone: true,
  imports: [
    PrimaryButtonComponent,
    OnlinePlayersComponent,
    ScoreBoardComponent,
    NgbTooltipModule,
    ClipboardModule
  ],
  templateUrl: './side-content.component.html',
  styleUrl: './side-content.component.scss'
})
export class SideContentComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  sideNavType: SidenavigationTypes = SidenavigationTypes.DEFAULT;
  sideNavTypes = SidenavigationTypes;
  allPlayers: Player[] = [];
  onlinePlayers: Player[] = this.signalService.onlinePlayers().map(o => new Player(o));
  sideNavdata: SideNavigation;
  userReferralLink: string;
  copying: boolean = false;

  constructor(
    private playgroundService: PlaygroundService,
    private router: Router,
    private signalService: SignalsService,
    private accountService: AccountsService,
    private authService: AuthService,
    private gameWebsocket: GameWsService
  ) {
    effect(() => {
      this.sideNavdata = this.signalService.sideContentData();
      this.sideNavType = this.signalService.sideContentData().type;
    });
  }

  ngOnInit(): void {
    this.authService.authenticated$.pipe(
      takeUntil(this.destroy$),
      mergeMap((authenticated) => {
        if (authenticated) {
          return this.accountService.userReferralLink();
        } else {
          return EMPTY;
        }
      })
    ).subscribe({
      next: (response: any) => {
        if (response) {
          this.userReferralLink = response.referral_link;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  newGame(): void { 
    this.resetInvitedPlayers();
    this.createGame();
  }

  practiceMode(): void { 
    this.router.navigate([`${Urls.APP}/${Urls.PRACTICE_MODE}`]);
  }

  invitePlayer(player: Player): void {
    this.resetInvitedPlayers();
    this.createGame(player);
  }

  createGame(player?: Player): void {
    this.playgroundService.startGame(player).subscribe((response) => {
      if (response) {
        this.router.navigate([`${Urls.APP}/${Urls.PLAYGROUND}`], { queryParams: { gameId: response.id } });
      }
    });
  }

  resetInvitedPlayers(): void {
    this.onlinePlayers.map(player => {
      player.deselect();
      return player;
    });
  }

  clipboardCopied(): void {
    this.copying = true;

    setTimeout(() => {
      this.copying = false;
    }, 1000);
  }
}
