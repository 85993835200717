import { Injectable } from "@angular/core";
import { Observable, mergeMap, of } from "rxjs";
import { AuthService } from "../services/auth.service";
import { ProblemService } from "../services/problem.service";
import { GameWsService } from "../services/web-sockets/game-ws.service";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root"
})
export class AppInitializer {
  
  constructor(
    private authService: AuthService,
    private problemService: ProblemService,
    private gameWS: GameWsService,
    private userService: UserService
  ) { }

  initialize(): Observable<any> | undefined {
    this.gameWS.connectToPlayerWS();
    return this.authService.refreshToken()?.pipe(
      mergeMap(() => this.problemService.getDomains()),
      mergeMap(() => this.userService.getUserDetails())
    );
  }
}
