<div class="d-flex justify-content-end">
  <app-profile-container [matMenuTriggerFor]="accountMenu" [profilePhotoUrl]="user?.profilePicture"></app-profile-container>

  <mat-menu #accountMenu="matMenu">
    <h3 mat-menu-item class="label">
      Welcome, <span style="font-weight: 500;">{{user?.fullName}}</span>
    </h3>
    <hr>

    <a class="mb-2" mat-menu-item [routerLink]="playerStatsRoute">
      <div class="d-flex justify-content-start align-items-center">
        <div class="menu-icon d-flex justify-content-center align-items-center me-2">
          <span class="material-symbols-outlined" style="color: var(--text-color-primary) !important; font-size: 25px;">
            user_attributes 
          </span>
        </div>
        <span class="label">Account</span>
      </div>
    </a>

    <a class="mb-2" mat-menu-item [routerLink]="gameHistoryRoute">
      <div class="d-flex justify-content-start align-items-center">
        <div class="menu-icon d-flex justify-content-center align-items-center me-2">
          <span class="material-symbols-outlined" style="color: var(--text-color-primary) !important; font-size: 25px;">
            sports_esports 
          </span>
        </div>
        <span class="label">Game History</span>
      </div>
    </a>

    <a class="mb-2" mat-menu-item [routerLink]="userRewardsRoute">
      <div class="d-flex justify-content-start align-items-center">
        <div class="menu-icon d-flex justify-content-center align-items-center me-2">
          <span class="material-symbols-outlined" style="color: var(--text-color-primary) !important; font-size: 25px;">
            award_star 
          </span>
        </div>
        <span class="label">Rewards</span>
      </div>
    </a>

    <a class="mb-2" mat-menu-item [routerLink]="activityRoute">
      <div class="d-flex justify-content-start align-items-center">
        <div class="menu-icon d-flex justify-content-center align-items-center me-2">
          <span class="material-symbols-outlined" style="color: var(--text-color-primary) !important; font-size: 25px;">
            timeline 
          </span>
        </div>
        <span class="label">Activity</span>
      </div>
    </a>


    <a class="mb-2" mat-menu-item [routerLink]="reportedIssuesRoute">
      <div class="d-flex justify-content-start align-items-center">
        <div class="menu-icon d-flex justify-content-center align-items-center me-2">
          <span class="material-symbols-outlined" style="color: var(--text-color-primary) !important; font-size: 25px;">
            problem 
          </span>
        </div>
        <span class="label">Issues</span>
      </div>
    </a>

    <a mat-menu-item [routerLink]="userFeedbacksRoute">
      <div class="d-flex justify-content-start align-items-center">
        <div class="menu-icon d-flex justify-content-center align-items-center me-2">
          <span class="material-symbols-outlined" style="color: var(--text-color-primary) !important; font-size: 25px;">
            forum 
          </span>
        </div>
        <span class="label">Feedback</span>
      </div>
    </a>
    
    <hr>
    <div class="d-flex justify-content-start" mat-menu-item>
      <a class="d-flex logout" (click)="logout()">
        <span class="material-symbols-outlined" style="color: var(--text-color-warning) !important; font-size: 25px;">
          logout 
        </span>
        <span style="color: var(--text-color-warning) !important; font-size: 17px; font-weight: 500;">Logout</span>
      </a>
    </div>
  </mat-menu>
</div>
