import { Component, OnDestroy, effect } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { User } from '../../models/user/User';
import { Subject } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { Router, RouterLink } from '@angular/router';
import { Urls } from '../../../constants/Urls';
import { ProfileContainerComponent } from '../profile-container/profile-container.component';
import { SignalsService } from '../../../services/signals.service';

@Component({
  selector: 'app-profile-menu',
  standalone: true,
  imports: [MatMenuModule, RouterLink, ProfileContainerComponent],
  templateUrl: './profile-menu.component.html',
  styleUrl: './profile-menu.component.scss'
})
export class ProfileMenuComponent implements OnDestroy {
  user: User;
  destroy$ = new Subject<boolean>();

  constructor(
    private signalService: SignalsService,
    private authService: AuthService,
    private router: Router
  ) {
    effect(() => {
      this.user = new User(this.signalService.userDetails());
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate([`${Urls.APP}/${Urls.HOME}`]);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get playerStatsRoute(): string {
    return `/${Urls.APP}/${Urls.ACCOUNT}`;
  }

  get userFeedbacksRoute(): string {
    return `/${Urls.APP}/${Urls.USER_FEEDBACK}`;
  }

  get reportedIssuesRoute(): string {
    return `/${Urls.APP}/${Urls.REPORTED_ISSUES}`;
  }

  get gameHistoryRoute(): string {
    return `/${Urls.APP}/${Urls.GAME_HISTORY}`;
  }

  get activityRoute(): string {
    return `/${Urls.APP}/${Urls.ACTIVITY_HISTORY}`;
  }

  get userRewardsRoute(): string {
    return `/${Urls.APP}/${Urls.USER_REWARDS}`;
  }
}
