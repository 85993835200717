import { AfterViewInit, Component, OnInit, effect } from '@angular/core';
import { ImageAssets } from '../../../constants/ImageAssets';
import { MatMenuModule } from '@angular/material/menu';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { User } from '../../models/user/User';
import { SignalsService } from '../../../services/signals.service';
import { UpdateUserPayload } from '../../models/payloads/UpdateUserPayload';
import { AccountsService } from '../../../services/accounts.service';

@Component({
  selector: 'app-theming-options',
  standalone: true,
  imports: [MatMenuModule, FontAwesomeModule],
  templateUrl: './theming-options.component.html',
  styleUrl: './theming-options.component.scss'
})
export class ThemingOptionsComponent {
  imageAssets = ImageAssets;
  selectedTheme: ThemeTypes = ThemeTypes.LIGHT;
  faCircleCheck = faCircleCheck;
  themeOptions: ThemeOptions[] = [
    {
      description: 'Default',
      previewImg: this.imageAssets.DEFAULT_THEME,
      type: ThemeTypes.LIGHT
    },
    {
      description: 'Dark',
      previewImg: this.imageAssets.DARK_THEME,
      type: ThemeTypes.DARK
    }
  ];
  user: User;

  constructor(private signalService: SignalsService, private accountsService: AccountsService) {
    effect(() => {
      this.user = new User(this.signalService.userDetails());
      document.body.setAttribute(
        'data-theme',
        this.user?.theme ?? ThemeTypes.LIGHT
      );
      this.selectedTheme = this.user.theme ?? ThemeTypes.LIGHT;
    });
  }

  onChangeTheme(theme: ThemeTypes): void {
    const payload: UpdateUserPayload = {
      first_name: this.user.firstName,
      last_name: this.user.lastName,
      username: this.user.userName,
      theme,
      profile_picture: null
    };

    this.accountsService.updateProfile(payload, false).subscribe({
      next: (response: any) => {
        this.signalService.userDetails.set(response);
        document.body.setAttribute(
          'data-theme',
          theme
        );
    
        this.selectedTheme = theme;
      },
      error: (err) => {
        console.log(err)
      }
    });
  }
}

interface ThemeOptions {
  description: string;
  previewImg: string;
  type: ThemeTypes;
}

export enum ThemeTypes {
  LIGHT = 'light',
  DARK = 'dark'
}
