<div class="d-flex justify-content-end">
  @if(unreadNotificationsCount > 0) {
    <div class="notif-count">
      <span style="font-size: 15px; color: var(--text-color-light);">{{unreadNotificationsCount}}</span>
    </div>
  }
  <div>
    <span 
      class="material-symbols-outlined" 
      style="font-size: 30px; color: var(--text-color-light);cursor: pointer;"
      [matMenuTriggerFor]="notifMenu">
      notifications
    </span>
  </div>
</div>

<mat-menu class="p-2" #notifMenu="matMenu" style="z-index: 999;">
  @if(!notifications.length) {
    <span style="color: var(--text-color-primary); font-size: 15px;">No notifications</span>
  } @else {
    <h2 class="title">Notifications ({{notifications.length}})</h2>
    @for (notification of notifications; track $index) {
      <div class="py-2" mat-menu-item style="border-top: 1px solid rgb(146, 117, 63);" [ngStyle]="{'background-color': notification.status === 'Sent' ? '#e9ecef' : ''}">
        <p class="m-0" style="color: var(--text-color-primary);"><span style="font-weight: 700;">{{notification.text.invitedBy || notification.text.userName}}</span> invited you to a game</p>
        <div class="d-flex mt-2 justify-content-end" *ngIf="notification.status === 'Sent'">
          <app-primary-button label="Accept" (onClick)="acceptRejectGameinvite(notification, true)"></app-primary-button>  
          <button type="button" class="ms-2 btn ais-secondary-btn" (click)="acceptRejectGameinvite(notification, false)">Reject</button>    
        </div>
        <span style="color: var(--text-color-primary); font-size: 12px;">{{notification.createdAt | formatDate}}</span>
      </div>
    }
  }
  
</mat-menu>
