import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ImageAssets } from '../../../constants/ImageAssets';
import { Urls } from '../../../constants/Urls';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { NotificationsComponent } from '../notifications/notifications.component';
import { ProfileMenuComponent } from '../profile-menu/profile-menu.component';
import { ThemingOptionsComponent } from '../theming-options/theming-options.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule, 
    CommonModule, 
    FontAwesomeModule,
    NotificationsComponent,
    ProfileMenuComponent,
    ThemingOptionsComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  imageAssets = ImageAssets;
  authenticated$: Observable<boolean>;

  faDiscord = faDiscord;
  faGlobe = faGlobe;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.authenticated$ = this.authService.authenticated$;
  }

  get registerRoute(): string {
    return `/${Urls.AUTH}/${Urls.USER_REGISTER}`
  }

  get loginRoute(): string {
    return `/${Urls.AUTH}/${Urls.LOGIN}`
  }

  get homeRoute(): string {
    return `/${Urls.APP}/${Urls.GAME_HISTORY}`;
  }

  get gameHistoryRoute(): string {
    return `/${Urls.APP}/${Urls.HOME}`;
  }
}
