<div class="d-flex justify-content-end">
  <span 
    class="material-symbols-outlined" 
    style="font-size: 30px;color: #FFFF;cursor: pointer;"
    [matMenuTriggerFor]="themes">
    contrast
  </span>

  <mat-menu #themes="matMenu">
    <div class="d-flex p-3">
      @for (theme of themeOptions; track i; let i = $index;) {
        <div class="me-2 d-flex flex-column theme-options-container" (click)="onChangeTheme(theme.type)">
          <div class="theme-preview-container">
            <img class="theme-img" [src]="theme.previewImg" loading="lazy">
          </div>
          <h4 class="label">{{theme.description}}</h4>
          @if (selectedTheme === theme.type) { <fa-icon class="selected" [icon]="faCircleCheck"></fa-icon>}
        </div>  
      }
      <!-- <div class="me-2 d-flex flex-column theme-options-container" (click)="onChangeTheme('light')">
        <div class="theme-preview-container">
          <img class="theme-img" [src]="imageAssets.DEFAULT_THEME" loading="lazy">
        </div>
        <h4 class="label">Default</h4>
        <i class="selected fa-regular fa-circle-check" *ngIf="selectedTheme === 'light'"></i>
      </div>

      <div class="d-flex flex-column theme-options-container"  (click)="onChangeTheme('dark')">
        <div class="theme-preview-container">
          <img class="theme-img" [src]="imageAssets.DARK_THEME" loading="lazy">
        </div>
        <h4 class="label">Dark</h4>
        <i class="selected fa-regular fa-circle-check"  *ngIf="selectedTheme === 'dark'"></i>
      </div> -->
    </div>
  </mat-menu>
</div>