import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { Urls } from './constants/Urls';
import { LandingComponent } from './pages/landing/landing.component';
import { RegisterComponent } from './pages/register/register.component';
import { AuthGuard, PreventLoggedInAccess } from './helpers/auth.guard';
import { PlaygroundComponent } from './pages/playground/playground.component';
import { GameHistoryComponent } from './pages/game-history/game-history.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { GameCompletedComponent } from './pages/game-completed/game-completed.component';
import { RewardsComponent } from './pages/rewards/rewards.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { ReportedIssuesComponent } from './pages/reported-issues/reported-issues.component';
import { UserFeedbacksComponent } from './pages/user-feedbacks/user-feedbacks.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
  {
    path: Urls.APP,
    children: [
      {
        path: Urls.HOME,
        canActivate: [PreventLoggedInAccess],
        loadComponent: () => import('./pages/home/home.component').then(c => c.HomeComponent)
      },
      {
        path: Urls.LANDING_PAGE,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/landing/landing.component').then(c => c.LandingComponent)
      },
      {
        path: Urls.PLAYGROUND,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/playground/playground.component').then(c => c.PlaygroundComponent)
      },
      {
        path: Urls.GAME_HISTORY,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/game-history/game-history.component').then(c => c.GameHistoryComponent)
      },
      {
        path: Urls.ACCOUNT,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/accounts/accounts.component').then(c => c.AccountsComponent)
      },
      {
        path: Urls.GAME_COMPLETED,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/game-completed/game-completed.component').then(c => c.GameCompletedComponent)
      },
      {
        path: Urls.USER_REWARDS,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/rewards/rewards.component').then(c => c.RewardsComponent)
      },
      {
        path: Urls.ACTIVITY_HISTORY,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/activity/activity.component').then(c => c.ActivityComponent)
      },
      {
        path: Urls.REPORTED_ISSUES,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/reported-issues/reported-issues.component').then(c => c.ReportedIssuesComponent)
      },
      {
        path: Urls.USER_FEEDBACK,
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/user-feedbacks/user-feedbacks.component').then(c => c.UserFeedbacksComponent)
      },
      {
        path: Urls.NOT_FOUND,
        loadComponent: () => import('./pages/not-found/not-found.component').then(c => c.NotFoundComponent)
      }
    ]
  }, 
  {
    path: Urls.AUTH,
    children: [
      {
        path: Urls.LOGIN,
        canActivate: [PreventLoggedInAccess],
        loadComponent: () => import('./pages/login/login.component').then(c => c.LoginComponent)
      }, 
      {
        path: Urls.USER_REGISTER,
        canActivate: [PreventLoggedInAccess],
        loadComponent: () => import('./pages/register/register.component').then(c => c.RegisterComponent)
      }
    ]
  },
  {
    path: '', 
    redirectTo: `${Urls.APP}/${Urls.HOME}`, 
    pathMatch: 'full'
  },
  {
    path: '**', 
    redirectTo: `${Urls.APP}/${Urls.NOT_FOUND}`, 
    pathMatch: 'full'
  }
];
