import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AppInitializer } from './helpers/app.initializer';
import { Observable, catchError, first, of } from 'rxjs';
import { ErrorInterceptor, errorInterceptor } from './helpers/interceptors/error.interceptor';
import { provideToastr } from 'ngx-toastr';
import { jwtInterceptor } from './helpers/interceptors/jwt.interceptor';

export function initializeApp(appInitializer: AppInitializer): () => Observable<any> | undefined {
  return () => appInitializer.initialize()?.pipe(catchError(() => of(null)), first())
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withViewTransitions()),
    provideHttpClient(withInterceptors([jwtInterceptor, errorInterceptor])),
    provideAnimations(),
    provideToastr(),
    { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [AppInitializer] },
  ]
};
