import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Player } from '../../models/player/Player';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { OnlineStatus } from '../../../constants/OnlineStatus';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { GameWsService } from '../../../services/web-sockets/game-ws.service';
import { PlayerDto } from '../../models/player/PlayerDto';
import { SignalsService } from '../../../services/signals.service';

@Component({
  selector: 'app-online-players',
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, PrimaryButtonComponent, FormatDatePipe],
  templateUrl: './online-players.component.html',
  styleUrl: './online-players.component.scss'
})
export class OnlinePlayersComponent {
  onlinePlayers: Player[] = [];
  @Output() invitePlayer = new EventEmitter<Player>();

  onlineStatuses = OnlineStatus;
  trackOnlineStatus = (index: number, onlinePlayer: Player) => onlinePlayer ? onlinePlayer.onlineStatus : undefined;

  constructor(private gameWebsocket: GameWsService, private signalService: SignalsService) {
    this.gameWebsocket.onlinePlayers.subscribe({
      next: (response: PlayerDto[]) =>{
        this.onlinePlayers = response.map(player => new Player(player));
        this.signalService.onlinePlayers.set(response.filter(d => d.online_status === 'online'));
      }
    });
  }

  invite(player: Player): void {
    this.invitePlayer.emit(player);
  }
}
